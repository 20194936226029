<template>
    <div class="m-5">
        <div class="flex items-center justify-between mb-2">
            <div class="flex items-center w-3/5">
                <div class="form-feedback form-feedback-right w-full">
                    <input v-model="search" ref="search"  type="text" class="form-control" placeholder="search refernce or customer name">
                    <div v-show="!search" class="form-control-feedback">
                        <span>
                            <i class="icon-search4 text-gray-400"></i>
                        </span>
                    </div>
                    <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                        <a @click.prevent="search = ''" href="#">
                            <i class="icon-cancel-circle2 text-red-500"></i>
                        </a>
                    </div>
                    <div v-show="filters.length > 0" ref="filterSearch" class="absolute inset-y-0 my-1 left-1 flex items-center rounded-l-sm overflow-hidden bg-white">
                        <div class="bg-gray-700 h-full flex items-center text-gray-100 px-1"><i class="icon-filter3" style="font-size: 12px"></i></div>
                        <div class="border border-l-0 border-gray-400 h-full flex items-center px-1">
                            <ul class="flex space-x-1">
                                <li v-for="(filter, i) in filters" :key="i">{{filter}}
                                    <span v-show="filters.length > 1 && filters.length > i+1" class="italic">or</span>
                                </li>
                                <li class="flex items-center">
                                    <a @click="clearFilter" href="#" class="flex hover:text-red-500"><i class="icon-cross3"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div ref="filter" class="ml-2 relative">
                    <button @click="showFilter = !showFilter" type="button" class="bg-white border whitespace-nowrap relative rounded-sm h-9 w-full focus:outline-none hover:bg-gray-50">
                        <div class="relative h-full flex items-center">
                            <div class="h-full w-9 bg-gray-200 rounded-l-sm flex justify-center items-center">
                                <i class="icon-filter3"></i>
                            </div>
                            <div class="pl-3">
                                <span class="text-gray-500 pr-5">Filter</span>
                            </div>
                            <div class="h-9 w-9 flex items-center justify-center ml-auto"> 
                                <i class="icon-arrow-down22"></i>
                            </div>
                        </div>
                    </button>
                    <div v-show="showFilter" class="absolute bg-white border mt-0.5 w-full">
                        <ul class="my-1">
                            <li v-for="(filter, i) in filterStatus" :key="i" class="hover:bg-gray-100">
                                <a @click="filter.status = !filter.status" href="#" class="flex items-center py-1 px-1">
                                    <div class="flex w-4">
                                        <i v-show="filter.status" class="icon-checkmark2 text-teal-500"></i>
                                    </div>
                                    <div class="ml-1" :class="{'font-medium': filter.status}">{{filter.text}}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
               <div class="flex items-center">
                    <div class="flex items-center mr-10">
                        <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                        <div class="flex items-center space-x-1">
                            <button @click="getData(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                            <button @click="getData(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                        </div>
                    </div>
                    <button class="h-7 w-7 bg-gray-100 border  -mr-px"><i class="icon-grid6"></i></button>
                    <button class="h-7 w-7 bg-gray-100 border"><i class="icon-list2"></i></button>
                </div>
            </div>
        </div>

        <div class="rounded-sm overflow-hidden">
            <table class="w-full">
                <thead>
                    <tr class="bg-blue-500 border border-blue-500 text-blue-50">
                        <th class="py-3 pl-5 pr-2" style="width: 15%">REFERENCE</th>
                        <th class="py-3 px-2" style="width: 20%">REMARKS</th>
                        <th class="py-3 px-2" style="width: 15%">WAREHOUSE</th>
                        <th class="py-3 px-2" style="width: 10%">STATUS</th>
                        <th class="py-3 px-2" style="width: 12%">VALIDATED</th>
                        <th class="py-3 px-2">USER</th>
                        <th class="py-3 px-2" style="width: 13%">DATE CREATED</th>
                    </tr>
                </thead>
                <tbody class="bg-white">
                    <tr v-for="(so, i) in stockopnames" :key="i" class="border" :class="{'bg-gray-50': i%2 == 0}">
                        <td class="py-2 pl-5 pr-2">
                             <router-link :to="'/stock-opname/detail/'+so._id" class="text-blue-500">
                                <div v-if="so.stockOpnameNo == 'In Progress'">/</div>
                                <div v-else> {{so.stockOpnameNo}}</div>
                            </router-link>
                        </td>
                        <td class="py-2 px-2">{{so.remarks}}</td>
                        <td class="py-2 px-2">{{so.warehouse}}</td>
                        <td class="py-2 px-2">{{so.status}}</td>
                        <td class="py-2 px-2">
                            <div v-if="so.validated">{{getDate(so.validated)}}</div>
                            <div v-else>--</div>
                        </td>
                        <td class="py-2 px-2">{{so.user}}</td>
                        <td class="py-2 pl-2 pr-5">{{getDate(so.createdAt)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right mt-5">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>

<script>
import BreadcrumbsManager from '../../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../../event-bus'
import Paginate from '../../../components/paginate.vue'
import axios from 'axios'
import {moment} from '../../../moment'
export default {
    components: {Paginate},
    mixins: [BreadcrumbsManager],
    data () {
        return {
            search: '',
            showFilter: false,
            filterStatus: [
                {status: true, text: 'In Progress'},
                {status: false, text: 'Validated'},
            ], 
            pages: '',
            stockopnames: [] 
        }
    },
    mounted() {
        this.getData()
        this.setButtonText('CREATE')
        this.setDiscard(false)
        this.setBreadcrumbs([
            {text: 'Stock Opname'}
        ])
        EventBus.$on('onClick', this.onCreate)
        window.addEventListener('click', this.closeFilter)
        this.$nextTick(() => {
            let w = this.$refs.filterSearch.clientWidth + 11
            this.$refs.search.style.paddingLeft = w+'px'
        })
    },
    destroyed() {
        EventBus.$off('onClick', this.onCreate)
        window.removeEventListener('click', this.closeFilter)
    },  
    computed: {
        filters () {
            let data = []
            for (let i=0; i<this.filterStatus.length; i++) {
                if (this.filterStatus[i].status == true) {
                    data.push(this.filterStatus[i].text)
                }
            }
            return data
        }
    },  
    methods: {
        getData (e) {
            window.scrollTo(0, 0)
            axios.get('/stockopname', {
                params: {
                    search: this.search,
                    filters: this.filters,
                    page: e
                }
            })
            .then(res => {
                this.pages = res.data.pages
                this.stockopnames = res.data.data
            })
        },
        clearFilter () {
            for (let i=0; i < this.filterStatus.length; i++) {
                this.filterStatus[i].status = false
            }
        },
        closeFilter (e) {
            if(!e || !this.$refs.filter.contains(e.target)) {
                this.showFilter = false
            }
        },
        onCreate () {
            this.$router.push('/stock-opname/create')
        },
        getDate (d) {
            return moment(d)
        }
    },
    watch: {
        filterStatus: {
            handler: function () {
                this.$nextTick(() => {
                    let w = this.$refs.filterSearch.clientWidth + 11
                    this.$refs.search.style.paddingLeft = w+'px'
                    this.getData()
                })
            },  
            deep: true
        }
    },
    beforeRouteLeave (to, from, next) {
        this.replaceBreadcrumb({
            find: 'Stock Opname',
            replace: {text: 'Stock Opname', path: this.$route.fullPath}
        })
        next()
    }
}
</script>